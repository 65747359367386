// import React from 'react'
// import { Redirect } from '@reach/router'

const NotFoundPage = () => {

    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}

export default NotFoundPage